/* body {
    padding: 20px;
    font-family: 'Raleway';
    display: flex;
    justify-content: center;
    font-size: 1.2em;
    color: var(--dark-terminal-color);
  } */

#payment-form>* {
    margin: 10px 0;
}

.StripeButton {
    background-color: var(--accent-color);
}

.StripeButton {
    background: var(--accent-color);
    border-radius: var(--radius);
    color: white;
    border: 0;
    padding: 12px 16px;
    margin-top: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
    display: block;
}

.StripeButton:hover {
    filter: contrast(115%);
}

.StripeButton:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
}

.StripeButton:disabled {
    opacity: 0.5;
    cursor: none;
}

/* input,
select {
    display: block;
    font-size: 1.1em;
    width: 100%;
    margin-bottom: 10px;
}

label {
    display: block;
}

a {
    color: var(--accent-color);
    font-weight: 900;
}

small {
    font-size: 0.6em;
}

fieldset,
input,
select {
    border: 1px solid #efefef;
}  */

#payment-form {
    padding: 20px;
    margin: auto;
    width: 500px;
}

#messages {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
    display: none;
    /* hide initially, then show once the first message arrives */
    background-color: #0a253c;
    color: #00d924;
    padding: 20px;
    margin: 20px 0;
    border-radius: var(--radius);
    font-size: 0.7em;
}

@media screen and (max-width: 1024px) {
}

@media (max-width: 768px) {
}

@media (max-width: 480px) {
    #payment-form {
        width: 370px;
    }
}

@media (max-width: 375px) {

}

@media (max-width: 320px) {
    #payment-form {
        width: 315px;
    }
}

@media (max-width: 280px) {
    #payment-form {
        width: 280px;
    }
}