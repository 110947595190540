@font-face {
  font-family: Inter;
  src: url('../src/Fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Inter;
  text-decoration: none !important;
}