.HomeAnimationButton {
  border-radius: 10px;
  border: none;
  color: white;
  width: 170px;
  margin-top: 10px;
  height: 50px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 19px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.SignUpButton {
  border-radius: 10px;
  border: none;
  color: white;
  width: 170px;
  margin-top: 10px;
  height: 50px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 19px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.UserButton {
  border-radius: 10px;
  border: none;
  color: white;
  width: 120px;
  height: 40px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.UserOrderButton {
  border-radius: 10px;
  border: 1px solid;
  color: #75757596;
  width: 100px;
  height: 40px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  font-family: Inter;
  background: transparent;
}

.UserSwitch {
  top: 5px;
}

.HomeAnimationButton:hover {
  opacity: 0.9;
}

.SignInAnimationButton {
  border-radius: 10px;
  border: none;
  color: white;
  margin-top: 25px;
  width: 267px;
  height: 51px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.SignInAnimationButton:hover {
  opacity: 0.9;
}

.NavItem {
  height: 80px;
  position: relative;
}

.LogoAnimation {
  padding: 15px;
  width: fit-content;
}

.BackHomeButton {
  width: fit-content;
  margin-top: 25px;
  margin-left: 25px;
  outline: none;
  cursor: pointer;
  user-select: none;
  position: absolute;
}

.HomeAnimation {
  width: 50%;
  height: 100%;
  display: table;
  float: right;
}

.AboutAnimation {
  width: 50%;
  height: 100%;
  display: table;
}

.SignInAnimation {
  margin: auto;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.MainInputWrapper {
  margin: auto;
  text-align: center;
  width: 556px;
  margin-top: 80px;
  height: 660px;
}

.MainInputWrapperSettings {
  margin: auto;
  text-align: center;
  width: 900px;
  margin-top: 80px;
  height: 840px;
  margin-bottom: 100px;
}

.SaveSettings {
  border-radius: 10px;
  border: none;
  color: white;
  width: 170px;
  margin-top: 10px;
  height: 50px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 19px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.ConfirmCheckout {
  border-radius: 10px;
  border: none;
  color: white;
  width: 170px;
  margin-top: 10px;
  height: 50px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 19px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.CompleteAccount {
  border-radius: 10px;
  border: none;
  color: white;
  width: 200px;
  margin-top: 10px;
  height: 50px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 19px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.UploadProductImage {
  border-radius: 10px;
  border: none;
  color: white;
  width: 154px;
  height: 40px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.pptable tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 1px !important;
}

.Errorstyleimagepost {
    font-size: 13px;
    color: red;
    margin-top: 10px;
}

.loading {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

#UploadedImages {
  width: 100px;
  height: 60px;
  object-fit: cover;
  padding: 10px;
}

#DeleteImages {
  border: none;
  color: #64646454;
  background-color: transparent;
  border-color: red;
}

.ImagesSize {
  font-size: 10pt;
}

#Images {
  display: none;
}

.NewPost {
  border-radius: 10px;
  border: none;
  color: white;
  width: 120px;
  margin-left: 30px;
  height: 40px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.setupstripebutton {
  border-radius: 10px;
  border: none;
  color: white;
  width: 230px;
  height: 40px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.DeletePost {
  border-radius: 10px;
  border: 1px solid;
  color: #75757557;
  width: 83px;
  height: 40px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  font-family: Inter;
  background: transparent;
}

.editPost path {
  stroke: black;
  filter: drop-shadow( 1px 1px 1px white);
}

.OrderComplete {
  border-radius: 10px;
  border: 1px solid;
  color: black;
  width: 210px;
  margin-top: 10px;
  margin-left: 40px;
  height: 40px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  font-family: Inter;
  background: transparent;
}

.orderview {
  width: 100%;
  line-break: anywhere;
}

.orderview td, tfoot, th, thead, tr {
    border: 1px solid black;
    text-align: left;
    padding: 3px;
}
/* .OrderComplete a:hover {
  background-color: yellow;
} */

.HelpMe {
  border-radius: 10px;
  border: 1px solid;
  color: #75757557;
  width: 120px;
  margin-top: 10px;
  height: 40px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 17px;
  font-family: Inter;
  background: transparent;
}

.DeleteAccount {
  border-radius: 10px;
  border: 1px solid;
  color: red;
  width: 100px;
  margin-top: 10px;
  height: 30px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 10px;
  font-family: Inter;
  background: transparent;
}

.RequestData {
  border-radius: 10px;
  border: 1px solid;
  color: green;
  width: 100px;
  margin-top: 10px;
  height: 30px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 10px;
  font-family: Inter;
  background: transparent;
  margin-left: 20px;
}

.HomeButtonMainNav {
  margin-left: 10px;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.CheckoutOptions {
  background: none;
  border: 0;
  margin: 0;
}

.InstaButton {
  transform: none;
  bottom: 20px;
  right: 20px;
  outline: none;
  position: fixed;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.TOS {
  bottom: 20px;
  right: 20px;
  text-decoration: none;
  position: absolute;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.ProductCard {
  margin: 10px;
  display: inline-table;
  cursor: pointer;
  border-radius: 10px;
}

.Cancelbox-content {
    margin: auto;
    top: 0 !important;
    bottom: 0;
    left: 0 !important;
    right: 0;
    background: rgb(255, 255, 255);
    width: 410px;
    height: 310px;
    padding: 20px;
    inset: 0;
    border-radius: 10px;
}

.Orderbox-content {
  margin: auto;
  top: 0 !important;
  bottom: 0;
  left: 0 !important;
  right: 0;
  background: rgb(255, 255, 255);
  width: 550px;
  height: auto;
  overflow: scroll;
  padding: 20px;
  inset: 0;
  border-radius: 10px;
  text-align: justify;
}

.my-popup-content {
  margin: auto;
  top: 0 !important;
  bottom: 0;
  left: 0 !important;
  right: 0;
  background: rgb(255, 255, 255);
  width: 393px;
  height: 216px;
  padding: 20px;
  inset: 0;
  border-radius: 10px;
  text-align: justify;
}

.RatingPopup-content {
  margin: auto;
  top: 0 !important;
  bottom: 0;
  left: 0 !important;
  right: 0;
  background: rgb(255, 255, 255);
  width: fit-content;
  height: 50%;
  padding: 20px;
  padding-bottom: 30px;
  inset: 0;
  border-radius: 10px;
  text-align: justify;
  overflow: scroll;
  position: fixed !important;
}

.NewPostPopup-content {
  margin: auto;
  top: 0 !important;
  bottom: 0;
  left: 0 !important;
  right: 0;
  background: rgb(255, 255, 255);
  width: fit-content;
  height: 615px;
  padding: 20px;
  padding-bottom: 30px;
  inset: 0;
  border-radius: 10px;
  text-align: justify;
  overflow: scroll;
  position: fixed !important;
}

.ProductPopup-content {
  margin: auto;
  top: 0 !important;
  bottom: 0;
  left: 0 !important;
  right: 0;
  background: rgb(255, 255, 255);
  width: fit-content;
  height: fit-content;
  inset: 0;
  border-radius: 10px;
  overflow: scroll;
  position: fixed !important;
}

.popup-arrow {
  display: none;
}

[role="tooltip"].popup-content {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup="tooltip"].popup-overlay {
  background: #000000c2;
}

.OrderCancel {
  border-radius: 10px;
  border: 1px solid;
  color: #75757591;
  width: 210px;
  margin-top: 10px;
  margin-left: 40px;
  height: 40px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  font-family: Inter;
  background: transparent;
}

.ConfirmButton {
  border-radius: 10px;
  border: none;
  color: white;
  width: 105px;
  margin-top: 10px;
  height: 40px;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.ReviewSubmitButton {
  border-radius: 10px;
  border: none;
  color: white;
  width: 105px;
  float: right;
  margin-top: 10px;
  height: 40px;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.NoButton {
  border-radius: 10px;
    border: solid;
    border-color: green;
    color: green;
    width: 250px;
    margin-right: 25px;
    margin-top: 10px;
    height: 40px;
    outline: none;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    font-family: Inter;
    background: transparent;
}

.YesButton {
    border-radius: 10px;
    border: solid;
    border-color: #00000052;
    color: #00000052;
    width: 60px;
    margin-top: 10px;
    height: 40px;
    outline: none;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    font-family: Inter;
    background: transparent;
}

.OrderButton {
    border-radius: 10px;
    border: solid;
    border-color: black;
    color: black;
    width: 250px;
    margin-top: 10px;
    height: 40px;
    outline: none;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    font-family: Inter;
    background: transparent;
}

.UserRating {
  margin-top: 10px;
  margin-left: 10px;
}

.SocialLink {
  font-size: 25px;
}

.ProductBuyButton {
  border-radius: 10px;
  border: none;
  color: white;
  margin-top: 15px;
  width: 140px;
  height: 50px;
  outline: none;
  cursor: pointer;
  user-select: none;
  font-size: 24px;
  font-family: Inter;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
}

.ProductImagesWrapper {
  overflow: hidden;
  width: 600px;
  height: 600px;
  margin-right: 40px;
}

.MuiChip-root {
  color: white !important;
  background: #f0929f !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #f0929f !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #f0929f !important;
}

.MuiRating-root {
  color: #f0929f !important;
}

.NewPostBottom {
  margin-bottom: 20px;
}

.UserLocIcon {
  margin-bottom: 5px;
  font-size: 20px;
}

.HoursOpen {
  padding-bottom: 10px;
}

.Verified {
  margin-bottom: 3px;
}

.HomeButton {
  margin-bottom: 6px;
}

.HomeMotionButton {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  float: right;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 22px;
  background: linear-gradient(
    314deg,
    rgba(182, 216, 243, 1) 4%,
    rgba(182, 216, 243, 1) 10%,
    rgba(245, 206, 224, 1) 100%
  );
  outline: none;
  border: none;
}

.CheckoutRow2 {
  margin-left: 20px;
}

.Errorstyle {
  font-size: 13px;
  color: red;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 2px;
}

.ErrorstyleCheckbox {
  font-size: 13px;
  color: red;
  margin-right: 100px;
  margin-top: 50px;
}

.mapboxgl-ctrl-geocoder--icon {
    display: none;
}

.mapboxgl-ctrl-geocoder--icon-search {
    display: none;
}

.mapboxgl-ctrl-geocoder--pin-right {
    display: none;
}

#geocoder {
    z-index: 1;
}

.mapboxgl-ctrl-geocoder--input {
    width: 268px;
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 10px;
    background: #f5f5f5;
    outline: none;
    padding-right: 10px;
    font-size: 14px;
    color: #757575;
}

.mapboxgl-marker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border:1px solid gray;
  background-color:lightblue;
}

.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.suggestions {
  background: #ffffff;
  overflow: scroll;
  border: 1px solid lightgrey;
  height: fit-content;
  position: absolute;
  width: 250px;
  font-size: 14px;
  padding-bottom: 10px;
  max-height: 210px;
  list-style-type: none;
}

.suggestions-wrapper ul { 
  padding-left: 0;
}

.suggestions li { 
  padding: 10px;
}

.suggestions li:hover { 
  background-color: #f2f2f2;
  cursor: pointer;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: 500;
  color: #101010;
}

.mapboxgl-ctrl-geocoder--suggestion-address {
  color: #333333;
}

.mapboxgl-ctrl-geocoder--powered-by {
  color: black;
  font-size: 8px;
  padding-top: 5px;
  padding-left: 10px;
}

.mapboxgl-ctrl-geocoder--powered-by a {
  color: black;
}

.mapboxgl-ctrl-geocoder--powered-by a:hover {
  color: black;
}

.MainNavLogo {
  text-decoration: none;
}

.MainNavLink {
  color: #f0929f;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
}

.MainNavLink:hover {
  color: #f0929f;
}

.infocost {
  position: relative;
  display: inline-block;
  cursor: help;
}

.infocost .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.infocost:hover .tooltiptext {
  visibility: visible;
}

@media screen and (max-width: 1024px) {
  .HomeAnimation {
    width: 100%;
    margin: auto;
    position: absolute;
  }
  .AboutAnimation {
    width: 100%;
    margin: auto;
    position: absolute;
  }

  .ProductImagesWrapper {
    width: 500px;
    height: 500px;
  }

  .CheckoutRow2 {
    margin-left: 0px;
  }

  .MainInputWrapperSettings {
    margin-bottom: 40px;
  }

  .ConfirmCheckout {
    margin-bottom: 30px;
  }
  
  .cheoutdivani {
    display: inline-block;
    width: 555px;
  }

  .UserRating {
    margin-top: 0;
    margin-left: 0;
  }
}

@media (max-width: 820px) {
  .ProductImagesWrapper {
    width: 450px;
    height: 450px;
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  .HomeButtonMainNav {
    display: none;
  }

  .UserLocIcon {
    margin-bottom: 2px;
    font-size: 12px;
  }

  .SocialLink {
    font-size: 20px;
  }

  .UserButton {
    width: 85px;
    height: 35px;
    font-size: 13px;
  }

  .UserOrderButton {
    width: 85px;
    height: 35px;
    font-size: 13px;
  }

  .NewPostPopup-content {
    width: 634px;
  }

  .NewPostMobile {
    float: right !important;
    width: 100% !important;
  }

  .SectionWrapperMobileRight {
    float: right !important;
  }

  .ProductImagesWrapper {
    width: 400px;
    height: 400px;
    margin-right: 25px;
  }

  .ProductBuyButton {
    width: 120px;
    height: 40px;
    font-size: 20px;
  }

  .AmountLeft {
    display: block;
    margin-top: 8px;
  }

  .CheckoutRow2 {
    margin-left: 20px;
  }
}

@media (max-width: 480px) {
  .HomeButtonMainNav {
    display: none;
  }

  .MainInputWrapper {
    width: 300px;
  }

  .UserRating {
    margin-top: 8px;
  }

  .Verified {
    margin-bottom: 1px;
  }

  .NewPostPopup-content {
    width: 320px;
  }

  .MainInputWrapperSettings {
    width: 295px;
    height: auto;
  }

  .ProductImagesWrapper {
    width: 370px;
    height: 340px;
    margin: 0;
  }

  .OrderComplete {
    margin-top: 30px;
    margin-left: 0px;
  }

  .TOS {
    right: 35px;
  }

  .Formsectionwrapper {
    float: none !important;
  }

  .NavItem {
    height: 65px;
    width: 55px;
  }

  .ProductCard {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .hideOnUser form {
    display: none;
  }

  .hideOnUser ul {
    margin-top: -75px;
  }

  .OrderCancel {
    margin-top: 50px;
    margin-left: 0px;
    margin-bottom: 50px;
  }

  .Orderbox-content {
    width: auto;
  }

  .Orderbox-content {
    word-break: break-all;
    font-size: 12px;
  }

  .orderview {
    table-layout: fixed;
  }

  .RatingPopup-content {
    width: 90%;
    height: 70%;
  }

  .Cancelbox-content {
    width: 90%;
    height: fit-content;
  }

  .NoButton { 
    width: 70%;
  }

  .YesButton {
    width: 20%;
  }
}

@media (max-width: 414px) {
  .mapboxgl-ctrl-geocoder--input {
    width: 255px;
  }
}

@media (max-width: 380px) {
  .ProductImagesWrapper {
    width: 350px;
    height: 340px;
  }
}

@media (max-width: 390px) {
  .my-popup-content {
    width: 280px;
    height: 270px;
  }
}

@media (max-width: 375px) {
  .ProductImagesWrapper {
    width: 330px;
    height: 300px;
  }

  .mapboxgl-ctrl-geocoder--input {
    width: 230px;
  }
}

@media (max-width: 360px) {
  .ProductImagesWrapper {
    width: 330px;
    height: 320px;
  }
}

@media (max-width: 320px) {
  .SignInAnimationButton {
    width: 200px;
    height: 40px;
    font-size: 12px;
    margin-top: 20px;
  }

  .ExploreMotionButton {
    width: 29px;
    height: 29px;
    margin-top: 3px;
  }

  .HomeButton {
    margin-bottom: 7px;
  }

  .NewPostPopup-content {
    height: 95%;
    width: 100%;
  }

  .ProductImagesWrapper {
    width: 275px;
    height: 245px;
  }

  .mapboxgl-ctrl-geocoder--input {
    width: 185px;
  }
}

@media (max-width: 280px) {
  .mapboxgl-ctrl-geocoder--input {
    width: 155px;
  }
}